<template>
  <div>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Input placeholder="请输入员工名称" v-model="storePrams.name"/>
        </div>
      </Col>
      <Col span="4">
        <div>
          <DatePicker placeholder="时间查询" style="width: 100%;" v-model="storePrams.create_time"></DatePicker>
        </div>
      </Col>
      <Col span="6">
        <div>
          <Button type="primary" icon="ios-search-outline" @click="searchValue">搜索</Button>
        </div>
      </Col>
    </Row>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Button v-if="jurisdictionList.indexOf('admin-works-add') !== -1" icon="md-add" type="primary" @click="addUser('add')">新增用户</Button>
        </div>
      </Col>
    </Row>
    <Table border :columns="worlkColumns" :data="data6">
      <template slot-scope="{ row, index }" slot="operation">
        <Button v-if="jurisdictionList.indexOf('admin-works-update') !== -1" type="primary" size="small" style="margin-right: 5px" @click="show(index,'edit',row)">编辑</Button>
        <Button v-if="jurisdictionList.indexOf('admin-works-del') !== -1" type="error" size="small" @click="remove(row)">删除</Button>
      </template>
      <template slot-scope="{ row }" slot="address">
        <div style="display: flex;align-items: center">
          <div style="margin-right: 5px">当前状态({{ row.admininfo.it_disabled=== 0 ?'已启用':'已禁用' }})</div>
          <Button type="warning" size="small" v-if="row.admininfo.it_disabled === 0" style="margin-right: 5px" @click="changeOff(row)">禁用</Button>
          <Button type="success" size="small" v-else style="margin-right: 5px" @click="changeOn(row)">启用</Button>
        </div>
      </template>
    </Table>
    <br/>
    <Page :total="total" :current="storePrams.page" style="text-align:right" show-elevator show-total @on-change="pageChange"
          :page-size="storePrams.limit"/>
    <Modal
        :title="formTitle"
        v-model="showModel"
        :mask-closable="false"
        width="600"
        class-name="vertical-center-modal"
        @on-ok="submit"
        :footer-hide="true"
        @on-cancel="cancel">
      <Form ref="formItem" :rules="ruleValidate" :model="formItem" :label-width="120" style="width: 500px">
        <FormItem label="用户账户" prop="account">
          <Input v-model="formItem.account" placeholder="请输入用户账户"></Input>
        </FormItem>
        <FormItem label="用户密码" prop="password">
          <Input v-model="formItem.password" type="password" placeholder="请输入用户密码"></Input>
        </FormItem>
        <FormItem label="员工名称" prop="staff_name">
          <Input v-model="formItem.staff_name" placeholder="请输入用户名称"></Input>
        </FormItem>
        <FormItem label="用户号码" prop="phone">
          <Input v-model="formItem.phone" placeholder="请输入用户号码"></Input>
        </FormItem>
        <FormItem label="平台数量" prop="store_num">
          <Input v-model="formItem.store_num" type="number" placeholder="请输入可创建的平台数量"></Input>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit('formItem')">确认</Button>
          <Button @click="handleReset('formItem')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
import {staffBan, staffList,staffAdd, staffInfo,staffEdit,staffDel} from '@/api/operation'
import moment from "moment";
export default {
  data () {
    var checkphone = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入手机号'))
          } else if (!this.isCellPhone(value)) {
            callback(new Error('请输入正确的手机号!'))
          } else {
            callback()
          }
        };
    return {
      worlkColumns: [
        {
          title: '用户账户',
          render: (h, params) => {
            return h('div', [
              h('div' , params.row.admininfo.account)
            ]);
          }
        },
        {
          title: '员工名称',
          key: 'staff_name'
        },
        {
          title: '平台数量',
          render: (h, params) => {
            return h('div', [
              h('div' , params.row.admininfo.store_num)
            ]);
          }
        },
        {
          title: '员工状态',
          slot:'address'
        },
        {
          title: '创建时间',
          key: 'created_at'
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          slot: 'operation'
        }
      ],
      data6: [],
      showModel:false,
      formItem:{
        account:'',
        password:'',
        staff_name:'',
        phone:'',
        store_num:'',
        staff_id:'',
        user_id:''
      },
      ruleValidate: {
        account: [
          { required: true, message: '请输入用户账户', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入用户密码', trigger: 'blur' }
        ],
        staff_name: [
          { required: true, message: '请输入员工名称', trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: checkphone,trigger: 'blur' },
        ],
        store_num: [
          { required: true, message: '请输入平台数量', trigger: 'blur' }
        ]
      },

      formTitle:'新增用户',
      total:0,
      storePrams: {
        limit: 10,
        page: 1,
        name: '',
        create_time: ''
      },
      jurisdictionList:[]
    }
  },
  created() {
    this.getList()
    this.jurisdictionList = this.$store.state.auth
  },
  mounted(){
  },
  methods: {
    isCellPhone (val) {
      if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
        return false
      } else {
        return true
      }
    },
    show (index,type,row) {
      if(type === 'edit'){
        this.formTitle='编辑用户信息'
      }
      staffInfo({staff_id:row.id}).then(res=>{
        this.formItem.account =res.data.admininfo.account
        this.formItem.password = res.data.password
        this.formItem.staff_name =res.data.staff_name
        this.formItem.phone =res.data.phone
        this.formItem.store_num =String(res.data.admininfo.store_num)
        this.formItem.staff_id = res.data.staff_id
        this.formItem.user_id = res.data.admininfo.user_id
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
      this.showModel=true
    },
    searchValue(){
      this.storePrams.create_time = moment(this.storePrams.create_time).format('YYYY-MM-DD')
      if (this.storePrams.create_time == 'Invalid date') {
        this.storePrams.create_time = ''
      }
      this.storePrams.page = 1
      this.getList()
    },
    getList(){
      staffList(this.storePrams).then(res=>{
        this.data6 = res.data.data
        this.total = res.data.total
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    pageChange(index) {
      this.storePrams.page = index
      this.getList()
    },
    changeOff(row){
      this.$Modal.confirm({
        title: '禁用提示',
        content: '请问您是否确认禁用此条用户',
        onOk: () => {
          if(row.admininfo.it_disabled === 0){
            let changeType = 1
            staffBan({user_id:row.admininfo.id,type:changeType}).then(res=>{
              this.getList()
              this.$Message.success(res.msg)
            }).catch(res=>{
              this.$Message.error(res.msg)
            })
          }
        },
        onCancel: () => {
        }
      });
    },
    changeOn(row){
      this.$Modal.confirm({
        title: '启用提示',
        content: '请问您是否确认启用此条用户',
        onOk: () => {
          if(row.admininfo.it_disabled === 1){
            let changeType = 0
            staffBan({user_id:row.admininfo.id,type:changeType}).then(res=>{
              this.getList()
              this.$Message.success(res.msg)
            }).catch(res=>{
              this.$Message.error(res.msg)
            })
          }
        },
        onCancel: () => {
        }
      });
    },
    remove(row) {
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此条用户，删除后无法恢复！！！',
        onOk: () => {
          staffDel({user_id:row.admininfo.id,staff_id : row.id}).then(res=>{
            this.$Message.success(res.msg)
            this.getList()
          }).catch(res=>{
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    addUser(type){
      if(type === 'add'){
        this.formTitle='新增用户'
      }
      this.showModel = true
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if(this.formItem.staff_id){
            staffEdit(this.formItem).then(res=>{
              this.$Message.success(res.msg)
              this.getList()
              this.reset()
              this.showModel=false
            }).catch(res=>{
              this.$Message.error(res.msg)
            })
          }else {
            staffAdd(this.formItem).then(res=>{
              this.$Message.success(res.msg)
              this.getList()
              this.reset()
              this.showModel=false
            }).catch(res=>{
              this.$Message.error(res.msg)
            })
          }

        } else {
          this.$Message.error('请输入用户信息');
        }
      })
    },
    handleReset (name) {
      this.$refs[name].resetFields();
    },
    submit(){

    },
    cancel(){

    },
    reset(){
      this.formItem.account =''
      this.formItem.password = ''
      this.formItem.staff_name = ''
      this.formItem.phone = ''
      this.formItem.store_num = ''
    }
  }
}
</script>
